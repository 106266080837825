// @import "primeng/resources/theme/lara-light-blue/theme.css";
// @import "primeng/resources/theme/primeng.css";
// @import "primeicons/primeicons.css";

// /* Bootstrap */
//  @import 'bootstrap';

/* Custom */
@import 'variables';
@import 'mixins';
@import 'custom';

//  body {
//   padding: 2em;
//   font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif, “Apple Color Emoji”, “Segoe UI Emoji”, “Segoe UI Symbol”;
// }

// .block-section {
//  //  padding-top: 50px;
//   // margin-bottom: 4rem
// }
// .block-content {
//   padding: 0;
//   border: 1px solid var(--surface-border);
//   border-top: 0 none
// }
html {
  font-size: 14px;
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background: var(--surface-ground);
  color: var(--text-color);
  // padding: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

@media screen and (max-height: 960px) {
  .rc-tab-table-container {
    display: flex;
    flex-direction: column;
    max-height: 580px;
    min-width: 300px;
  }

  .mat-table {
    overflow: auto;
    max-height: 550px;
  }
}

@media screen and (min-height: 960px) {
  .rc-tab-table-container {
    display: flex;
    flex-direction: column;
    // max-height: 750px;
    min-width: 300px;
  }

  .mat-table {
    overflow: auto;
    //  max-height: 750px;
  }
}

.rc-edit-form-bg {
  background-color: whitesmoke !important;
  border: 2px solid rgb(120, 121, 129);
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
// .rc-edit-form-bg-width {
//   width: 100% !important;
// }
// .rc-table-bg {
//   background-color:whitesmoke !important;
// }
// .rc-panel-main-heading {
//   font-family:Verdana,sans-serif;
//   font-weight:bold;
//   font-size:16px;
//   color: #301c66;
//    margin: 0;
//    text-align: center;
//  }
.portal-title {
  // text-align: center;
  // font-size: 3em;
  text-transform: uppercase;
  // text-decoration: underline;
  // text-decoration-color: rgb(35, 32, 218);
  color: #ffd900;
  text-shadow: 2px 2px rgb(167, 23, 23);
  transform-style: preserve-3d;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  // color: white;
  // fill: rgba(55, 53, 47, 0.6);
  // background: rgba(55, 53, 47, 0.08);
  // floating-btn-shadow: $btn-shadow-light,
}

.rc-container {
  background-color: rgb(0, 117, 193) !important;
  color: white !important;
  height: 100%;
}
.rc-container-no-height-bg {
  background-color: rgb(0, 117, 193); // rgb(145, 27, 181);
  color: white;
  // height:auto !important;
}
.portal-subtitle {
  background-color: $agp-blue;
  color: white !important;
  align-items: center;
  font-size: 18px;
  min-height: 50px;
  font-weight: bold;
  justify-content: center;
}
.portal-title2 {
  color: #ffd900;
}

.portal-mat-card {
  // max-width: 400px;
  border: 5px solid rgb(120, 121, 129);
  border-radius: 10px;
}
.portal-background {
  padding: 10px 0 0 0;
  position: relative;
  height: 100%;
  // background-color: #46a33a !important;

  .actions {
    text-align: center;
    // margin: 50px;
    z-index: 1;

    span {
      display: inline-block;
      font-weight: bold;
      padding: 20px 10px 30px 10px;
    }

    a {
      margin: 0 5px 10px 0;

      &.actions-main {
        text-transform: uppercase;
        padding: 3px 24px;
      }

      mat-icon {
        position: relative;
        bottom: 1px;
      }
    }
  }

  .get-started {
    // max-width: 700px;
    margin: 20px auto;
    letter-spacing: 0.01px;
    overflow-wrap: break-word;
    z-index: 1;
    overflow: hidden;

    code {
      font-size: 0.9em;
      display: inline-block;
      word-wrap: break-word;
      white-space: normal;
      margin: 0 0 10px 0;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url('/assets/img/portalBackground.jpg');
    background-size: cover;
    opacity: 0.3;
    content: '';
    z-index: 0;
  }

  .gradient {
    &::before {
      position: absolute;
      top: 41%;
      left: 0;
      bottom: 0;
      right: 0;
      content: '';
      z-index: 0;
    }
  }

  .container {
    position: relative;
  }
}
