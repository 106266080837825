// @use '@angular/material' as mat;
// @import '@angular/material/theming';

$toolbar-breakpoint: 600px;

// $fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';

$link-hover-decoration: none;
$label-margin-bottom: 0;
$agp-blue: #283593;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// .font-xs {
//   font-size: rem(12px) !important;
// }

// .font-sm {
//   font-size: rem(13px) !important;
// }

// .font-lg {
//   font-size: rem(14px) !important; // H4
// }

// .font-xl {
//   font-size: rem(16px) !important; // H3
// }

// .font-2xl {
//   font-size: rem(20px) !important; // H2
// }

// .font-3xl {
//   font-size: rem(36px) !important; // H1
// }
